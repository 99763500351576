<template>
  <div class="inner">
  </div>
</template>
<script>
  export default {
	data() {
	  return {
	    pdfh5: null
	  };
	},
	mounted() {}
  }
</script>

<style scoped>
	*{
	padding: 0;
	margin: 0;
	}
	html,body,.inner {
	width: 100%;
	height: 100%;
	}
</style>